import axios from 'axios';
const BASE_URL = 'https://chattyserver.vercel.app/api/';

const config = {
  headers:{
    'Content-Type' :'application/x-www-form-urlencoded',
    //'Access-Control-Allow-Origin' : 'ORIGIN'
  }
};

export const getAnswer = async (question: string) => {
  try {
    const res = await axios.post(
      `${BASE_URL}ask-a-question`,
      {question},
      config
    );
    return res?.data?.result;
  } catch (e) {
    console.error(e);
    return 'Something went wrong!! ☹️';
  }
};

export const getTranslatedText = async (query: string, language: string) => {
  try {
    const res = await axios.post(`${BASE_URL}translate`, {query, language});
    return res?.data?.result;
  } catch (e) {
    console.error(e);
    return 'Something went wrong!! ☹️';
  }
};